<template>
  <b-row>
    <b-col cols="12">
      <form-wizard-number 
      :jenisPenyampaianOptions="jenisPenyampaianOptions.options"
      :kantorPenghubungOptions="kantorPenghubungOptions.options"
      :majelisHakimOptions="majelisHakimOptions.options"
      :jenisLaporanOptions="jenisLaporanOptions.options"
   
      :diterimaMelaluiOptions="diterimaMelaluiOptions.options"
      :badanPeradilanTingkat1Options="badanPeradilanTingkat1Options.options"
      :badanPeradilanTingkat2Options="badanPeradilanTingkat2Options.options"
      :badanPeradilanTingkat3Options="badanPeradilanTingkat3Options.options"
      :jenisPerkaraOptions="jenisPerkaraOptions.options"
      :lingkunganPeradilanOptions="lingkunganPeradilanOptions.options"
      :provinsiOptions="provinsiOptions.options"
      :kotaOptions="kotaOptions.options"
      :jenisDokumenOptions="jenisDokumenOptions.options"
      :sumberInformasiOptions="sumberInformasiOptions.options"
      :jenisInformasiOptions="jenisInformasiOptions.options"
      :localData="localData"
      @emitLoadJenisInformasi="onchangeSumberInformasi"
      @emitLoadKota="onchangeProvinsi"

      @emitUpdateDataLaporan="emitUpdateDataLaporan"
      @emitgetDetailDataLaporan="emitgetDetailDataLaporan"
      @emitgetTerlapors="emitgetTerlapors"
      @onChangeJenisPenyampaian="onChangeJenisPenyampaian"
      />
    </b-col>
    <b-col cols="12"
      v-if="$route.params.laporan_id"
    >
      <b-tabs
        pills
        nav-class="mb-3"
      >
        <!-- general tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Pelapor</span>
          </template>

          <pelapor />
          <!-- <view-info-card :user-data="rowData" /> -->
        </b-tab>
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Kuasa Pelapor</span>
          </template>

          <kuasa-pelapor />
          <!-- <view-dokumen-foto
            :user-data="rowData"
            :dataJenisDokumen="dataMasterJenisDokumenFoto"
            :canManage="false"
            :assetId="assetId"
          /> -->
        </b-tab>
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Terlapor</span>
          </template>

          <terlapor :buttonOff=true />
          <!-- <view-dokumen-foto
            :user-data="rowData"
            :dataJenisDokumen="dataMasterJenisDokumenFoto"
            :canManage="false"
            :assetId="assetId"
          /> -->
        </b-tab>
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Kelengkapan Data</span>
          </template>

          <kelengkapan-data />
          <!-- <view-dokumen-lainnya
            :user-data="rowData"
            :dataJenisDokumen="dataMasterJenisDokumenLainnya"
            :canManage="false"
            :assetId="assetId"
          /> -->
        </b-tab>
        <!--/ dokumen tab -->
      </b-tabs>
    </b-col>  
    <b-col cols="12">
      <div class="d-flex mt-1">
        <b-button v-if="$route.params.laporan_id" class="btn btn-primary" variant="primary" @click="updateData">Kirim Data Laporan</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import KelengkapanData from '@/views/kelengkapan-data/list/List.vue'
import FormWizardNumber from './FormWizardNumber.vue'

import {
  getJenisPenyampaian,
  getKantorPenghubung,
  getJenisPerkara,
  getLingkunganPeradilan,
  getProvinsi,
  getKota,
  getMajelisHakim,
  getJenisLaporan,
  getDugaanPelanggaran,
  getDiterimaMelalui,
  getBadanPeradilanTingkat1,
  getBadanPeradilanTingkat2,
  getBadanPeradilanTingkat3,
  getJenisPenyampaianById,
  getJenisDokumen,
  getJenisInformasi,
  getSumberInformasi,
  getTerlapor,
  getMasterLaporan,
} from '@/connection-api/master'
import {
  generateNomorPenerimaan,
  createDataLaporan,
  updateDataLaporan,
  getDetailDataLaporan,
} from '@/connection-api/laporan'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    FormWizardNumber,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    KelengkapanData,

  },
  data() {
    return {
      localData: {
        jenis_penyampaian_id: null,
        jenis_pihak_terlibat_id: null,
        jenis_dokumen_id: null,
        nota_kesepahaman_terkait: null,
        terlapos: null,
        provinsi_id: null,
        diterima_melalui_id: null,
        jenis_laporan: null,
        informasi: {
          jenis_informasi_id: null,
          sumber_informasi_id: null,
        },
        kota: { id: null }
      },
      tokenCreate: '',
      sumberInformasiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Sumber Informasi --' },
        ],
      },
      jenisInformasiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Informasi --' },
        ],
      },
      jenisPenyampaianOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Penyampaian --' },
        ],
      },
      kantorPenghubungOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kantor Penghubung --' },
        ],
      },
      diterimaMelaluiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Diterima Melalui --' },
        ],
      },
      kantorPenghubungOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kantor Penghubung --' },
        ],
      },
      jenisPerkaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Perkara --' },
        ],
      },
      lingkunganPeradilanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Lingkungan Peradilan --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi Lingkungan Peradilan --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kab/Kota Lingkungan Peradilan --' },
        ],
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      majelisHakimOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Majelis/Hakim--' },
        ],
      },
      jenisLaporanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Laporan--' },
        ],
      },
      // dugaanPelanggaranOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Jenis Dugaan Pelanggaran--' },
      //   ],
      // },
      badanPeradilanTingkat1Options: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Badan Peradilan Tk. 1--' },
        ],
      },
      badanPeradilanTingkat2Options: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Badan Peradilan Tk. 2--' },
        ],
      },
      badanPeradilanTingkat3Options: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Badan Peradilan Tk. 3--' },
        ],
      },
    }
  },
  async mounted() {
    if(!this.$route.params.laporan_id)
    {
      const responseCreate = await createDataLaporan()
      if(responseCreate.data.success === true)
      {
        this.localData.id = responseCreate.data.id
        this.$router.push({
            name: 'penerimaan-edit', 
            params: { id: responseCreate.data.id }
        });
      }
    }
    else
    {
      this.emitgetDetailDataLaporan(this.$route.params.laporan_id)
      this.emitgetTerlapors(this.$route.params.laporan_id)
    }

    
    const responseMasterLaporan = await getMasterLaporan();

    // const responseJenisPenyampaian = await getJenisPenyampaian()
    this.mappingSelectOption(responseMasterLaporan.data.jenis_penyampaian, this.jenisPenyampaianOptions.options)

    // const responseKantorPenghubung = await getKantorPenghubung()
    this.mappingSelectOption(responseMasterLaporan.data.kantor_penghubung, this.kantorPenghubungOptions.options)

    // const responseMajelisHakim = await getMajelisHakim()
    this.mappingSelectOption(responseMasterLaporan.data.majelis_hakim, this.majelisHakimOptions.options)

    // const responseDiterimaMelalui = await getDiterimaMelalui()
    this.mappingSelectOption(responseMasterLaporan.data.diterima_melalui, this.diterimaMelaluiOptions.options)

    // const responseJenisLaporan = await getJenisLaporan()
    this.mappingSelectOption(responseMasterLaporan.data.jenis_laporan, this.jenisLaporanOptions.options)

    // const responseBadanPeradilanTingkat1 = await getBadanPeradilanTingkat1()
    this.mappingSelectOption(responseMasterLaporan.data.badan_peradilan_tingkat1, this.badanPeradilanTingkat1Options.options)

    // const responseBadanPeradilanTingkat2 = await getBadanPeradilanTingkat2()
    this.mappingSelectOption(responseMasterLaporan.data.badan_peradilan_tingkat2, this.badanPeradilanTingkat2Options.options)

    // const responseBadanPeradilanTingkat3 = await getBadanPeradilanTingkat3()
    this.mappingSelectOption(responseMasterLaporan.data.badan_peradilan_tingkat3, this.badanPeradilanTingkat3Options.options)

    // const responseJenisPerkara = await getJenisPerkara()
    this.mappingSelectOption(responseMasterLaporan.data.jenis_perkara, this.jenisPerkaraOptions.options)

    // const responseLingkunganPeradilan = await getLingkunganPeradilan()
    this.mappingSelectOption(responseMasterLaporan.data.lingkungan_peradilan, this.lingkunganPeradilanOptions.options)

    // const responseProvinsi = await getProvinsi()
    this.mappingSelectOption(responseMasterLaporan.data.provinsi, this.provinsiOptions.options)

    // const responseSumberInformasi = await getSumberInformasi()
    this.mappingSelectOption(responseMasterLaporan.data.sumber_informasi, this.sumberInformasiOptions.options)


  },
  methods: {
    async updateData() {
      const responseUpdateLaporan = await updateDataLaporan(this.localData)

      if(responseUpdateLaporan.data.success === true)
      {
        this.makeToast('success', responseUpdateLaporan.data.message)
        setTimeout(() => {
          this.$router.push({ name: 'laporan-readonly', params: this.$route.params.laporan_id })
        }, 1000);
      }else if(responseUpdateLaporan.data.success === false){
        this.makeToast('danger', responseUpdateLaporan.data.message)
      }
    },
    async onchangeSumberInformasi(sumberInformasiId) {
      const data = {
        sumber_informasi_id: sumberInformasiId
      }
      this.jenisInformasiOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Informasi --' },
        ],
      }
      const resp = await getJenisInformasi(data)
      this.mappingSelectOption(resp.data, this.jenisInformasiOptions.options)
      // this.localData.kota_id = null
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
      // this.localData.kota_id = null
    },

    async emitgetDetailDataLaporan(id)
    {
      const response = await getDetailDataLaporan(id)
      if(response.data.success === true)
      {
        this.localData = response.data.data
        
        if (this.localData.informasi.sumber_informasi_id) {
          const dataSearch = {
            sumber_informasi_id: this.localData.informasi.sumber_informasi_id
          }
          const respJenisInformasi = await getJenisInformasi(dataSearch)
          this.jenisInformasiOptions = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Jenis Informasi --' },
            ],
          }
          this.mappingSelectOption(respJenisInformasi.data, this.jenisInformasiOptions.options)
        }
        if (this.localData.provinsi_id) {
          await this.onchangeProvinsi(this.localData.provinsi_id);
          // this.localData.kota_id = this.localData.kota_id;
        }
      }
      else
      {
        this.makeToast('success', response.data.message)
      }
    },
    async emitgetTerlapors(laporan_id)
    {

    
      const data = {
        laporan_id: laporan_id
      }
      const response = await getTerlapor(data)
      // console.log(response)
      if(response.data.total > 0)
      {

        this.localData.terlapors = response.data.data
       
      }
      else
      {
        this.makeToast('success', response.data.message)
      }
    },
    async emitUpdateDataLaporan(params)
    {
      const response = await updateDataLaporan(params)
      if(response.data.success === true)
      {
        // if(params.tahap === 3){
        //   this.makeToast('success', response.data.message)
        // } 
      }
    },
    async onChangeJenisPenyampaian(jenisPenyampaianId) {
      const responseJenisPenyamapaian = await generateNomorPenerimaan(jenisPenyampaianId)
      
    },
     mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Laporan',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
}

</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
